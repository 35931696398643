<template>
    <div class="input-quac-file" :class="{'mb-4': showDeleteBtn}">
        <input
            type="file"
            name="input-file-pqr"
            :id="'input-file-pqr'+IdInput"
            accept=".pdf, image/png, image/jpeg, image/jpg"
            @change="previewFiles"
        >
        <label :for="'input-file-pqr'+IdInput">
            Cargar archivo
        </label>
        <label class="input-file-name col" :style="showDeleteBtn ? 'padding-right: 50px' : ''" :for="'input-file-pqr'+IdInput">
            {{ nameDoc }}
        </label>
        <button class="btn-delete" type="button" @click="$emit('deleteInput', IdInput)" v-if="showDeleteBtn">
            <img src="@/assets/icons/icon-delete.svg">
        </button>
    </div>
</template>
<script>
export default {
    data() {
        return {
            dataInput: null,
            nameDoc: 'Documento',
        }
    },
    props: {
        IdInput: {
            type: Number,
            require: true,
            default: 99,
        },
        showDeleteBtn: {
            type: Boolean,
            require: false,
            default: false,
        },
        maxSize: {
            type: Number,
            require: false,
            default: 10000000
        }
    },
    methods: {
        previewFiles(event) {
            if (event.target.files.length > 0) {
                if(event.target.files[0].size < this.maxSize) {
                    this.nameDoc = event.target.files[0].name
                    let newDocument = event.target.files
                    this.$emit('updateDataFiles', {
                        data: newDocument,
                        idInput: 'input-file-quac-'+this.IdInput
                    })
                } else {
                    alert('Recuerda que el archivo debe pesar menos de 5 Megas')
                }
            }
        }
    },
}
</script>