<template>
    <div class="container-page-pqr">
        <div class="header-page">
            <div class="container-breadcrumb">
                <router-link class="text-breadcrumb" :to="{name: 'Home'}">Inicio</router-link>
                <span class="text-breadcrumb">PQRS por cuotas</span>
            </div>
            <img src="@/assets/img-bg-pqr.jpg" class="w-100 mb-5">
            <h1>
                PQRS POR CUOTAS
            </h1>
        </div>
        <div class="container-body-pqr">
            <div class="container mt-0" id="form-register-pqrs">
                <h3 class="text-center mb-4">¡Conocer tu opinión nos ayuda a descubrir un mundo de posibilidades!</h3>
                <p>
                    Registra aquí tu PQRS, nuestro equipo de Servicio al Cliente la recepcionará dentro de los horarios de atención (Lunes a viernes de 8:00 am a 5:30 pm), por favor diligencia cada una de las casillas relacionadas en el formulario y si lo requieres, adjunta los soportes que avalen tu solicitud.
                </p>
                <p>
                    Emitiremos respuesta conforme a la Ley 1480 del 2011 (Estatuto del Consumidor) y el Decreto 1074 del 2015, dentro de los 15 días hábiles siguientes a la recepción de la PQRS.
                </p>
                <form @submit.prevent="evalFormPQR" class="form-send-pqr pt-5" id="form-pqrs">
                    <div class="col-12 col-md-6 pe-md-2 position-relative">
                        <select
                            class="select-quac w-100 mb-4"
                            @blur="validateBlur('tipoDoc')"
                            @focus="errorTipoDoc = false"
                            v-model="tipoDoc"
                            name="selecTipoDoc"
                            id="selecTipoDoc"
                        >
                            <option :value="null">Tipo de documento</option>
                            <option
                                :key="index"
                                v-for="(tipo, index) in tiposDoc"
                                :value="tipo.id"
                            >
                                {{ tipo.nombre }}
                            </option>
                        </select>
                        <span v-if="errorTipoDoc" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="tipoDoc">Tipo de documento</span>
                    </div>
                    <div class="col-12 col-md-6 ps-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="number"
                            @blur="validateBlur('document')"
                            @focus="errorDocument = false"
                            placeholder="Documento"
                            v-model="document"
                        >
                        <span v-if="errorDocument" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="document">Documento</span>
                    </div>
                    <div class="col-12 col-md-6 pe-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="text"
                            @blur="validateBlur('name')"
                            @focus="errorName = false"
                            placeholder="Nombres"
                            v-model="name"
                        >
                        <span v-if="errorName" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="name">Nombres</span>
                    </div>
                    <div class="col-12 col-md-6 ps-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="text"
                            @blur="validateBlur('lastName')"
                            @focus="errorLastName = false"
                            placeholder="Apellidos"
                            v-model="lastName"
                        >
                        <span v-if="errorLastName" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="lastName">Apellidos</span>
                    </div>
                    <div class="col-12 col-md-6 pe-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="number"
                            @blur="validateBlur('celular')"
                            @focus="errorCelular = false"
                            placeholder="Celular"
                            v-model="celular"
                        >
                        <span v-if="errorCelular" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="celular">Celular</span>
                    </div>
                    <div class="col-12 col-md-6 ps-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="email"
                            @blur="validateBlur('email')"
                            @focus="errorEmail = false"
                            placeholder="Email"
                            v-model="email"
                        >
                        <span v-if="errorEmail" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="email">Email</span>
                    </div>
                    <div class="col-12 col-md-6 pe-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="text"
                            @blur="validateBlur('direccion')"
                            @focus="errorDireccion = false"
                            placeholder="Dirección"
                            v-model="direccion"
                        >
                        <span v-if="errorDireccion" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="direccion">Dirección</span>
                    </div>
                    <div class="col-12 col-md-6 ps-md-2 position-relative">
                        <input
                            class="input-quac w-100 mb-4"
                            type="text"
                            @blur="validateBlur('ciudad')"
                            @focus="errorCiudad = false"
                            placeholder="Ciudad"
                            v-model="ciudad"
                        >
                        <span v-if="errorCiudad" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="ciudad">Ciudad</span>
                    </div>
                    <div class="col-12 col-md-6 pe-md-2 position-relative">
                        <select
                            class="select-quac w-100 mb-4"
                            @blur="validateBlur('tipoSoli')"
                            @focus="errorTipoSoli = false"
                            v-model="tipoSoli"
                            name="selecTipoDoc"
                            id="selecTipoDoc"
                        >
                            <option :value="null">Tipo de solicitud</option>
                            <option
                                :key="index"
                                v-for="(tipo_soli, index) in tiposSolicitud"
                                :value="tipo_soli.idProceso"
                            >
                                {{ tipo_soli.proceso }}
                            </option>
                        </select>
                        <span v-if="errorTipoSoli" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="tipoSoli">Tipo de solicitud</span>
                    </div>
                    <div class="col-12 col-md-6 ps-md-2 position-relative">
                        <select
                            class="select-quac w-100 mb-4"
                            @blur="validateBlur('dependenciaSoli')"
                            @focus="errorDependenciaSoli = false"
                            v-model="dependenciaSoli"
                            name="dependenciaSoli"
                            id="dependenciaSoli"
                        >
                            <option :value="null">Dependencia de la solicitud</option>
                            <option
                                :key="index"
                                v-for="(dependencia_soli, index) in dependenciasSolicitud"
                                :value="dependencia_soli.idDependencia"
                            >
                                {{ dependencia_soli.descripcion }}
                            </option>
                        </select>
                        <span v-if="errorDependenciaSoli" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="dependenciaSoli">Dependencia de la solicitud</span>
                    </div>
                    <div class="col-12 position-relative mb-4">
                        <textarea
                            class="w-100 mb-3 input-quac"
                            @focus="errorDescripcionSoli = false"
                            @blur="validateBlur('descripcionSoli')"
                            v-model="descripcionSoli"
                            name="descripcionSoli"
                            id="descripcionSoli"
                            rows="5"
                            placeholder="Ingresa la información necesaria para revisión del caso (Números, valores, referencias entre otros)."
                        ></textarea>
                        <span v-if="errorDescripcionSoli" class="error-inputs">Este campo es obligatorio</span>
                        <span class="label-input" v-if="descripcionSoli">Descripción de la solicitud</span>
                    </div>
                    <p><b>Adjunta aquí los soportes de tu PQRS</b></p>
                    <p class="w-100">Recuerda cargar archivos (jpg, png, jpeg, pdf) de menos de 2 Megas.</p>
                    <div class="input-quac d-flex flex-wrap py-3 mb-4">
                        <div class="col-12 mb-4 mb-md-0 col-md-9 col-lg-10 pe-md-4">
                            <InputFileQuac
                                :key="id"
                                :id-input="id"
                                :show-delete-btn="inputsFiles.length > 1"
                                :max-size="2000000"
                                v-for="id in inputsFiles"
                                @deleteInput="deleteInputFileQuac($event)"
                                @updateDataFiles="updateDataFilesQuac($event)"
                            />
                        </div>
                        <button
                            class="btn-add-new-input col mb-auto"
                            type="button"
                            v-if="inputsFiles.length < maxCantFiles"
                            @click="increaseFiles()"
                        >
                            + Agregar
                        </button>
                    </div>
                    <div class="mb-4 pb-3 d-flex align-items-center justify-content-center position-relative">
                        <label class="d-flex mt-1 mb-auto mx-0 ms-md-1 me-3">
                            <input
                                class="tyc-checkbox"
                                required
                                type="checkbox"
                                checkmark="checkmark"
                                id="acept-t-y-c"
                                v-model="status"
                            />
                            <span class="checkmark"></span>
                        </label>
                        <label for="acept-t-y-c" class="mb-0 text-start" style="font-size: 1rem">
                            Acepto la política de tratamiento de datos personales que se encuentra publicada en la página web de <a href="https://www.quac.co" target="_blank">www.quac.co</a>
                            Leer Autorizacion Tratamiento de datos personales.
                        </label>
                    </div>
                    <button
                        class="buton buton-primary mb-4 mx-auto px-5"
                        :disabled="loading"
                        type="submit"
                    >
                        {{ loading ? 'Cargando...' : 'Enviar' }}
                    </button>
                </form>
            </div>
        </div>
        <b-modal id="modal-summary-pqrs" v-model="showModal" centered hide-footer hide-header>
            <div class="text-center px-4">
                <h4>{{ summaryPQRS.title }}</h4>
                <p>{{ summaryPQRS.sub_title }}</p>
                <div class="container-number-ticket"><span>Número de ticket: <strong>{{ summaryPQRS.n_ticket }}</strong></span></div>
                <p>{{ summaryPQRS.message }}</p>
                <button
                    class="buton buton-primary mb-3 mx-auto px-5"
                    @click="closeModalSuccess()"
                >
                    Entendido
                </button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {v4 as uuidv4} from 'uuid';
import InputFileQuac from '../components/InputFileQuac.vue';
export default {
    components: {
        InputFileQuac,
    },
    data() {
        return {
            inputsFiles: [1],
            cantFiles: 1,
            maxCantFiles: 3,
            inputsDataFiles: [],
            tipoDoc: null,
            errorTipoDoc: false,
            document: '',
            errorDocument: false,
            showAllForm: false,
            name: '',
            errorName: false,
            lastName: '',
            errorLastName: false,
            celular: '',
            errorCelular: false,
            email: '',
            errorEmail: false,
            direccion: '',
            errorDireccion: false,
            ciudad: '',
            errorCiudad: false,
            tipoSoli: null,
            errorTipoSoli: false,
            dependenciaSoli: null,
            errorDependenciaSoli: false,
            descripcionSoli: '',
            errorDescripcionSoli: false,
            status: false,
            errorTYC: false,
            uuidSesion: null,
            loading: false,
            showModal: false,
        }
    },
    computed: {
        ...mapState('pqrQuac',
            [
                'tiposSolicitud',
                'dependenciasSolicitud',
                'tiposDoc',
                'summaryPQRS'
            ]),
    },
    watch: {
        celular() {
            if (this.celular.toString().length > 10) {
                this.celular = this.celular.toString().slice(0, 10)
            }
        }
    },
    mounted() {
        this.uuidSesion = uuidv4()
        this.getMotivosPQR()
        this.getDependenciasPQR()
        this.getTipoDoc()
        let badgetGrecaptcha = document.querySelector('.grecaptcha-badge')
        let btnWapp = document.querySelector('.btn-whatsapp')
        if (badgetGrecaptcha) {
            badgetGrecaptcha.style.display = 'block'
            badgetGrecaptcha.style.visibility = 'visible'
        }
        if (btnWapp) {
            btnWapp.style.bottom = '5rem'
        }
    },
    beforeDestroy() {
        let badgetGrecaptcha = document.querySelector('.grecaptcha-badge')
        let btnWapp = document.querySelector('.btn-whatsapp')
        if (badgetGrecaptcha) {
            badgetGrecaptcha.style.display = 'none'
            badgetGrecaptcha.style.visibility = 'hidden'
        }
        if (btnWapp) {
            btnWapp.style.bottom = '1rem'
        }
    },
    methods: {
        ...mapActions('pqrQuac', ['sendPQRQuac', 'getTiposSolicitudes', 'getMotivosPQR', 'getDependenciasPQR', 'getTipoDoc']),
        async evalFormPQR() {
            this.validateBlur('tipoDoc')
            this.validateBlur('document')
            this.validateBlur('name')
            this.validateBlur('lastName')
            this.validateBlur('celular')
            this.validateBlur('email')
            this.validateBlur('direccion')
            this.validateBlur('ciudad')
            this.validateBlur('tipoSoli')
            this.validateBlur('dependenciaSoli')
            this.validateBlur('descripcionSoli')
            this.validateBlur('aceptTYC')
            if (
                !this.errorTipoDoc &&
                !this.errorDocument &&
                !this.errorName &&
                !this.errorLastName &&
                !this.errorCelular &&
                !this.errorEmail &&
                !this.errorDireccion &&
                !this.errorCiudad &&
                !this.errorTipoSoli &&
                !this.errorDependenciaSoli &&
                !this.errorDescripcionSoli &&
                !this.errorTYC
            ) {
                this.loading = true
                let elThis = this
                /* eslint-disable-next-line */
                await grecaptcha.ready(async function() {
                    /* eslint-disable-next-line */
                    await grecaptcha.execute('6LfRrCIqAAAAACf78rFpiQwf-8_Yminw7Z-bCA-0', { action: 'submit' })
                        .then(async function(token) {
                            if (token) {
                                await elThis.sendPQRQuac({
                                    'inputsDataFiles': elThis.inputsDataFiles,
                                    'tipoDoc': elThis.tipoDoc,
                                    'document': elThis.document,
                                    'name': elThis.name,
                                    'lastName': elThis.lastName,
                                    'celular': elThis.celular,
                                    'email': elThis.email,
                                    'direccion': elThis.direccion,
                                    'ciudad': elThis.ciudad,
                                    'tipoSoli': elThis.tipoSoli,
                                    'dependenciaSoli': elThis.dependenciaSoli,
                                    'descripcionSoli': elThis.descripcionSoli,
                                    'uuid': elThis.uuidSesion
                                })
                                if (elThis.summaryPQRS) {
                                    elThis.showModal = true;
                                }
                            }
                            elThis.loading = false
                        })
                    elThis.uuidSesion = uuidv4()
                })
            } else {
                location.href = '#form-register-pqrs'
            }
        },
        validateBlur(input){
            switch (input) {
                case 'tipoDoc':
                    this.errorTipoDoc = !this.tipoDoc
                    break;
                case 'document':
                    this.errorDocument = !(this.document.length >= 5)
                    break;
                case 'name':
                    this.errorName = !(this.name.length > 2)
                    break;
                case 'lastName':
                    this.errorLastName = !(this.lastName.length > 2)
                    break;
                case 'celular':
                    // eslint-disable-next-line
                    const re = /^3[0-9]{9}/
                    this.errorCelular = !(re.test(this.celular) && this.celular.length < 11)
                    break;
                case 'email':
                    // eslint-disable-next-line
                    const re2 = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    this.errorEmail = !(re2.test(this.email))
                    break;
                case 'direccion':
                    this.errorDireccion = !(this.direccion.length > 3)
                    break;
                case 'ciudad':
                    this.errorCiudad = !(this.ciudad.length > 3)
                    break;
                case 'tipoSoli':
                    this.errorTipoSoli = !this.tipoSoli
                    break;
                case 'dependenciaSoli':
                    this.errorDependenciaSoli = !this.dependenciaSoli
                    break;
                case 'descripcionSoli':
                    this.errorDescripcionSoli = !this.descripcionSoli
                    break;
                case 'aceptTYC':
                    this.errorTYC = !this.status
                    break;
                default:
                    break;
            }
        },
        deleteInputFileQuac(idInput){
            let searchInput = this.inputsFiles.findIndex(e => e == idInput)
            if (searchInput >= 0) {
                this.inputsFiles.splice(searchInput, 1)
                let searchIdInput = this.inputsDataFiles.findIndex(e => e.idInput == 'input-file-quac-'+idInput)
                if (searchIdInput >= 0) {
                    this.inputsDataFiles.splice(searchIdInput, 1)
                }
            }
        },
        updateDataFilesQuac(data) {
            let findIndex = this.inputsDataFiles.findIndex((element) => element.idInput == data.idInput)
            if (findIndex >= 0) {
                this.inputsDataFiles[findIndex] = data
            } else {
                this.inputsDataFiles.push(data)
            }
        },
        increaseFiles() {
            if (this.inputsFiles.length < this.maxCantFiles) {
                this.cantFiles += 1
                this.inputsFiles.push(this.cantFiles)
            }
        },
        closeModalSuccess() {
            this.showModal = false
            this.inputsFiles = [this.inputsFiles[0] + 10]
            this.cantFiles = 1
            this.inputsDataFiles = []
            this.tipoDoc = null
            this.errorTipoDoc = false
            this.document = ''
            this.errorDocument = false
            this.showAllForm = false
            this.name = ''
            this.errorName = false
            this.lastName = ''
            this.errorLastName = false
            this.celular = ''
            this.errorCelular = false
            this.email = ''
            this.errorEmail = false
            this.direccion = ''
            this.errorDireccion = false
            this.ciudad = ''
            this.errorCiudad = false
            this.tipoSoli = null
            this.errorTipoSoli = false
            this.dependenciaSoli = null
            this.errorDependenciaSoli = false
            this.descripcionSoli = ''
            this.errorDescripcionSoli = false
            this.status = false
            this.errorTYC = false
            this.uuidSesion = uuidv4()
            window.scrollTo(0, 0)
        }
    },
}
</script>
<style lang="scss">
.container-page-pqr {
    .container {
        width: 80%;
        max-width: 1400px;
        margin: 36px auto;
        position: relative;
        z-index: 1;
        @media (max-width: 768px) {
            width: 90%;
        }
    }
    .container-body-pqr {
        padding: 1rem 0rem;
        padding-bottom: 5rem;
        margin-right: auto;
        margin-left: auto;
        position: relative;
        min-height: 100dvh;
        &::before {
            content: url('../assets/decorador-politicas-1.svg');
            position: absolute;
        }
        &::after {
            content: url('../assets/decorador-politicas-2.svg');
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .container {
            position: relative;
            z-index: 1;
        }
    }
}
.container-number-ticket {
    margin-bottom: 1rem;
    margin-right: auto;
    span {
        background-color: rgb(217, 217, 217);
        padding: .4rem 1rem;
        border-radius: 5px;
    }
}
</style>